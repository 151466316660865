// 1. Global
// ---------

$global-font-size: 62.5%;
$global-width: 120rem;
$global-lineheight: 1.5;

$body-font-family: $lato;
$body-antialiased: true;
$body-font-size: 1.6rem;

$global-margin: 1rem;
$global-padding: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;

// Internal variables used for text direction
$global-text-direction: ltr;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);


body:after {
	display: none;
	background-color: rgba(0,0,0, .5);
	color: white;
	padding: .3em;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;

	@include sm {
		content: "Small";
	}

	@include md {
		content: "Medium";
	}

	@include xmd {
		content: "X-Medium";
	}

	@include lg {
		content: "Large";
	}

	@include xl {
		content: "Xlarge";
	}

	@include xxl {
		content: "XXlarge";
	}

	@include xxxl {
		content: "XXXlarge";
	}
}