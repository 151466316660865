#site-main {
	max-width: 90rem;

	@include xl {
		max-width: 90rem;
	}

	header {

		@include md {
			height: 13.6rem;
			display: flex;
			align-items: center;
		}

		h4 {

			@include md {
				text-align: left;
				margin-bottom: 0;
				color: $light-gray;
			}
		}
	}
	
	section {
		margin-bottom: 6rem;

		&.intro {

			@include xmd {

				.polygon {
					shape-outside: polygon(30px 70px,-10px 200px,80px 240px,320px 270px,320px 0);
				    width: 360px;
				    height: 240px;
					float: right;
					opacity: .2;
				}
			}

			@include lg {

				.polygon {
					shape-outside: polygon(-40px 160px,-100px 320px,80px 380px,320px 270px,320px 0px,240px 100px);
					width: 390px;
				}
			}

			@include xl {

				.polygon {
					display: none;
				}
			}
		}
		
	}

	

	table.contact {
		width: 100%;
		max-width: 50rem;

		tr {
			display: flex;

			td {
				padding: 0;
				width: 50%;
				position: relative;

				a {
					position: absolute;
					bottom: 0;

				}
			}
		}
	}

}