
aside#site-aside {

	section	{
		margin-bottom: 3.5rem;

		table {
			font-size: 0.9em;

			@include mobile {
			    font-size: 2rem;
			}

			td {
				padding-right: 1.5em;
				padding-bottom: 0.5em;
			}
		}
	}

	h1.logo {
		text-indent: -9999px;
		width: 238px;
		height: 110px;
		max-width: 100%;

		a {
			display: block;
			width: 238px;
			height: 110px;
			max-width: 100%;
			background: url(../img/logo-dokter-elisa-de-bel.svg) no-repeat top center;
		}
	}
}

