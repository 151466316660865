/* -------------------------------------------------------------------------
 Form elements
---------------------------------------------------------------------------- */
@mixin forms {
	input[type="text"], 
	input[type="search"], 
	input[type="submit"], 
	button,
	textarea {
	    appearance: none;
	    outline: none;
	    border: none;
	    border-radius: 0;
	}
}