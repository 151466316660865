html,
body {
	overflow-x: hidden;
	min-width: 320px;
}

.border-top {
	border-top: 1rem solid $black;
	width: 100%;
	height: 1rem;

	@include md {
		position: fixed;
	}
	
}

.wrapper {
	padding: 2rem 0;
	display: flex;
	flex-direction: row;
	wrap: no-wrap;

	@include mobile {
		flex-direction: column;
		max-width: 450px;
		margin: 0 auto;
	}

	@include xmd {
		background: url(../img/womans-belly.png) no-repeat right top -1.4rem transparent;
		background-size: 40rem;
	}

	@include lg {
		background-size: 50rem;
	}

	@include xl {
		background-size: 55rem;
	}
}

.lines {
	margin: 0;
	padding: 0;
	position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: -1;
    margin-left: 29rem;

    li {
    	display: block;
    	width: 1px;
    	height: 100%;
    	background: $lightest-gray;
    	margin-right: 23.9%;
    	position: relative;

    	@include mobile {
    		display: none;

    		// &:first-child {
    		// 	display: block;
    		// }
    	}
    }
}

aside#site-aside,
main#site-main {
	padding: 0 1.5rem;

	@include md {
		padding: 0 2.5rem;
	}
}


aside#site-aside {

	@include md {
		width: 30rem;
		position: fixed;
	}
}

main#site-main {

	@include md {
		margin-left: 30rem;
	}

}

h2 {
	margin-bottom: 1.5em;
}

h3 {
	margin: 1.5em 0;
}

h4 {
	font-weight: 300;
	font-style: italic;
	color: #444;
	margin-bottom: 2.5em;
}

p {
	font-size: 1.4rem;
}

small {
	line-height: 1.4;
	display: inline-block;
}

.btn {

	&.btn-afspraken {
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#397aea+0,39bde5+100 */
		background: #397aea; 
		background: -moz-linear-gradient(left, #397aea 0%, #39bde5 100%); 
		background: -webkit-linear-gradient(left, #397aea 0%,#39bde5 100%);
		background: linear-gradient(to right, #397aea 0%,#39bde5 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#397aea', endColorstr='#39bde5',GradientType=0 );
		
		color: $white;
		padding: .2em 1.8em;
		border-radius: 0.7em;
		display: inline-block;

		i {
			position: relative;
			font-size: 0.9em;
			top: 0.05em;
			padding-right: 0.8em;
		}
	}
}

.phone-link {
	color: $black;

	@include mobile {
	    font-size: 1.2em;
	}

	i {
		position: relative;
		font-size: 0.8em;
		top: 0.05em;
		padding-right: 0.6em;
	}
}


#map {
	height: 400px;  /* The height is 400 pixels */
	width: 100%;  /* The width is the width of the web page */
}