/* Foundation v2.1.4 http://foundation.zurb.com */
/* Artfully masterminded by ZURB  */

/* -------------------------------------------------- 
   Table of Contents
-----------------------------------------------------
:: Shared Styles
:: Page Name 1
:: Page Name 2
*/


/* -----------------------------------------
   Shared Styles
----------------------------------------- */

table th { 
	font-weight: bold;
}
table td, table th { 
	padding: 9px 10px;
	text-align: left;
}

table.calendar {
	width: 100%;
	max-width: 60rem;
	margin-bottom: 3rem;
	font-weight: 300;

	thead {

		tr {
			background: $lightest-gray;

			th {
				text-align: center;
				padding: .5rem 1.5rem;
				width: 11rem;
			}
		}
	}

	tbody {

		tr {

			td {
				text-align: center;
				background: rgba($white, .8);
			}

			td:first-child {
				text-align: left;
				background-color: $lightest-gray;
				font-weight: 900;
			}
		}
	}
}

.pinned {

	@include md {
		display: none;
	}
}

/* Mobile */
@media only screen and (max-width: 767px) {

	
	table.responsive { 
		margin-bottom: 0;
	}
	
	.pinned { 
		background-color: $lightest-gray;
		position: absolute;
		left: 0;
		top: 0;
		width: 11rem;
		overflow: hidden;
		overflow-x: scroll;
	}
	
	.pinned table { 
		border-right: none;
		border-left: none;
		width: 100%;
	}
	
	.pinned table th, .pinned table td { 
		white-space: nowrap;
	}
	.pinned td:last-child { 
		border-bottom: 0;
	}
	
	div.table-wrapper { 
		position: relative;
		margin-bottom: 20px;
		overflow: hidden;
	}

	// div.table-wrapper:after { 
	// 	content: "";
	// 	display: block;
	// 	position: absolute;
	// 	top: 40px;
	// 	right: 0;
	// 	width: 7rem;
	// 	height: 100%;
	// 	background: $white; 
	// 	background: -moz-linear-gradient(left, rgba($white, 0) 0%, $white 80%); 
	// 	background: -webkit-linear-gradient(left, rgba($white, 0) 0%,$white 80%);
	// 	background: linear-gradient(to right, rgba($white, 0) 0%,$white 80%);
	// }

	div.table-wrapper div.scrollable { 
		margin-left: 11rem;
	}

	div.table-wrapper div.scrollable { 
		overflow: scroll;
		overflow-y: hidden;
		margin-bottom: 0rem;
		cursor: ew-resize;
	}
	
	table.responsive td, table.responsive th { 
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		padding: .9rem 3.5rem;
	}

	table.responsive th:first-child, table.responsive td:first-child, table.responsive td:first-child, table.responsive.pinned td { 
		display: none;
	}

	
	
}
